import * as React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Link
} from "@mui/material";
import BackButton from "../components/backbutton";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";

// MUI Styling
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const agendaData = [
  {
    title: "Lunes, 14 de Noviembre",
    events: [
      {
        title: "Registración Conferencia & Check-in Hotel",
        desc: "",
        startTime: "14:00",
        timeDetails: " 14:00 - 15:30",
      },
      {
        title:
          'Visita a la hacienda "La Montanera" (Transportación desde el Hotel Sheraton)',
        desc: "",
        startTime: "16:00",
        timeDetails: " 16:00 - 19:00 ",
        expand: true,
        moreDetails: (
          <>
            <>
              Visita a la hacienda La Montanera. Visitaremos la finca de la
              familia Fernandez Garcia, La Montanera, una hacienda de 226 años,
              donde se produce un exquisito jamón de bellota "cerdo negro" 100%
              argentino. Conoceremos la bodega y degustaremos sus productos.
            </>
            <br/>
            <br/>
            <>
             <Link href="https://goo.gl/maps/ipXSQUKLkWYviHCBA"> Dirección: RP 24 km 5, 5 Finca Santo Domingo, A4403 Cerrillos,
              Salta, Argentina </Link> <br/><br/>
              <Link href="https://cerdonegro.com.ar/nosotros"> Nosotros - Cerdo Negro de Quico Fernández</Link>
            </>
          </>
        ),
      },
      {
        title: "Cena - Adobe Cocina Regional",
        desc: "",
        startTime: "20:30",
        timeDetails: " 20:30 -  ",
        expand: true,
        moreDetails: (
          <>
            <>
             <Link href="https://goo.gl/maps/NYGJyj2mYh6EbhZ48"> Dirección: Caseros 511 Primer Piso, A4400 Salta, Argentina</Link> <br/>
            </>
          </>
        ),
      },
    ],
  },
  {
    title: "Martes, 15 de Noviembre",
    events: [
      {
        title: "Momento de Seguridad",
        desc: "",
        speaker: "Hotel Staff",
        startTime: "8:15",
        timeDetails: " 8:15 - 8:25 ",
      },
      {
        title: "Bienvenida e Introducción",
        desc: "",
        speaker: "Grace Alvarez",
        startTime: "8:25",
        timeDetails: " 8:25 - 9:00",
        expand: true,
        moreDetails: (
          <>
            Siemens Energy empezó a cotizar en Frankfurt Stock Exchange el 28 de
            septiembre de 2020 – y ahora es una empresa joven e independiente,
            aunque con una fuerte herencia que aprovechamos para construir
            nuestro propio legado, mucho más ágil y enfocado. <br />{" "}
            <b>
              Nuestra cultura, nuestros valores y comportamiento son clave:
              escuchamos a nuestros clientes{" "}
            </b>
            y adaptamos nuestras soluciones y procesos a sus necesidades. <br />
            
          </>
        ),
      },
      {
        title: "Siemens Energy | Gas Services",
        desc: "",
        speaker: "Fadi Ghantous",
        startTime: "9:00",
        timeDetails: " 9:00 - 9:30",
        expand: true,
        moreDetails: (
          <>
            Escuche de nuestro nuevo{" "}
            <b>
              VP de Servicios para la Región de Latinoamérica Fadi Ghantous{" "}
            </b>
            , quiénes somos en SE Gas Services y cómo nos hemos reorganizado
            recientemente para ofrecerle mejores servicios.
          </>
        ),
      },
      {
        title:
          "Nuevos desafíos: Hidrógeno, Almacenamiento de Energía, Compensador Síncrono",
        desc: "",
        speaker: "Pablo Colmenares",
        startTime: "9:30",
        timeDetails: " 9:30 - 10:45 ",
        expand: true,
        moreDetails: (
          <>
            Siemens Energy es una de las empresas de tecnología energética
            líderes en el mundo. La empresa trabaja con sus clientes y socios en
            sistemas de energía para el futuro, apoyando así la transición hacia
            un mundo más sostenible. Las turbinas de gas de Siemens Energy ya
            son capaces de funcionar con hasta un 100 por ciento de hidrógeno
            verde cuando la infraestructura para los combustibles climáticamente
            neutrales finalmente se instale. Para 2030, las turbinas de gas de
            la compañía estarán 100 por ciento preparadas para hidrógeno, pero
            hasta entonces, necesitamos soluciones que puedan tener un impacto
            inmediato hoy.{" "}
          </>
        ),
      },
      {
        title: "Espacio para Café",
        desc: "",
        startTime: "10:45",
        timeDetails: "10:45 - 11:00",
      },
      {
        title: "Flota  - SGT5-4000F: Novedades",
        desc: "",
        speaker: "Steven Sandhaas",
        startTime: "11:00",
        timeDetails: " 11:00 - 11:45",
        expand: true,
        moreDetails: (
          <>
            Las turbinas de gas de Siemens Energy cumplen con altos requisitos
            de un amplio espectro de aplicaciones en términos de eficiencia,
            confiabilidad, y flexibilidad mientras cumplen con las normas
            ambientales. Dependiendo de sus requisitos, Siemens Energy
            proporciona la solución y el alcance adecuado para sus necesidades
            específicas del mercado. Con base a los requerimientos del mercado
            en América Latina, esta sesión se enfoca en mejoras de potencia,
            eficiencia y flexibilidad para las turbinas de gas 4000F y 2000E.
          </>
        ),
      },
      {
        title: "Flota  - SGT5-2000E: Novedades",
        desc: "",
        speaker: "Steven Sandhaas",
        startTime: "11:45",
        timeDetails: " 11:45- 12:30 ",
        expand: false,
        moreDetails: (
          <>
            Las turbinas de gas de Siemens Energy cumplen con altos requisitos
            de un amplio espectro de aplicaciones en términos de eficiencia,
            confiabilidad, y flexibilidad mientras cumplen con las normas
            ambientales. Dependiendo de sus requisitos, Siemens Energy
            proporciona la solución y el alcance adecuado para sus necesidades
            específicas del mercado. En base a los requerimientos del mercado en
            América Latina, esta sesión se enfoca en mejoras de potencia,
            eficiencia y flexibilidad para las turbinas de gas 4000F y 2000E.
          </>
        ),
      },
      {
        title: "Almuerzo",
        desc: "",
        startTime: "12:30",
        timeDetails: " 12:30 - 13:30 ",
      },
      {
        title: "Mercado Eléctrico en Argentina",
        desc: "",
        speaker: "Juan José Aranguren",
        startTime: "13:40",
        timeDetails: " 13:40 -14:40 ",
        expand: true,
        moreDetails: (
          <>
            El Ingeniero Juan José Aranguren nos traerá un pantallazo de las
            expectativas del mercado eléctrico en la región, con un foco más
            detallado de la Argentina. Una mirada crítica y entendida del rol de
            la transición energética. Como evaluarla para armar un plan acorde a
            fundamentos sólidos que permiten proyectar un horizonte.{" "}
          </>
        ),
      },
      {
        title: "Oportunidades: Minería y Litio",
        desc: "",
        speaker: "Juan José Aranguren",
        startTime: "14:40",
        timeDetails: " 14:40  - 15:40  ",
        expand: true,
        moreDetails: (
          <>
            Hemos pensado en otras opciones que se ofrecen en Argentina. ¿Ha
            pensado en diversificarse o al menos evaluar otras opciones de
            negocios? Esta presentación de Ing. Aranguren abrirá al diálogo para
            entender mejor el modelo de negocio y las oportunidades que puedan
            presentarse en el área de la minería.{" "}
          </>
        ),
      },
      {
        title: "Espacio para Café",
        desc: "",
        startTime: "15:40 ",
        timeDetails: "15:40  - 16:00  ",
      },
      {
        title: "Modelos Avanzados - SGT 9000HL",
        desc: "",
        speaker: "Jorge Luna",
        startTime: "16:00 ",
        timeDetails: " 16:00  - 17:15  ",
        expand: true,
        moreDetails: (
          <>
            Siemens Energy es uno de los tecnólogos líder global de turbinas
            clase avanzada. Durante esta sesión, escucharemos la evolución de la
            tecnología Siemens, los recientes éxitos en proyectos que ya operan
            comercialmente y la descarbonización atribuída por estas turbinas de
            alta eficiencia. Conozca los aspectos de planta que modularizarían
            la construcción que directamente aumentan la eficiencia en campo, y
            a la misma vez mantienen una alta calidad y seguridad de los
            equipos. Todo esto en conjunto con un programa de servicio y
            mantenimiento optimizado para el beneficio de los operados y
            usuarios de nuestros clientes.{" "}
          </>
        ),
      },
      {
        title: "Conclusiones de la Jornada",
        desc: "",
        speaker: "Joe Matos / Sebastian Diaz",
        startTime: "17:15 ",
        timeDetails: " 17:15  - 17:30  ",
      },
      {
        title: "Anticipo agenda Miércoles y Detalles para la Cena ",
        desc: "",
        speaker: "Grace Alvarez",
        startTime: "17:30 ",
        timeDetails: " 17:30  - 17:45  ",
      },
      {
        title: "Punto de encuentro en el Lobby Hotel Sheraton ",
        desc: "",
        speaker: "Todos",
        startTime: "19:30 ",
        timeDetails: " 19:30 -  ",
      },
      // {
      //   title: "Transporte al restaurante ",
      //   desc: "",
      //   speaker: "Todos",
      //   startTime: "19:30 ",
      //   timeDetails: " 19:30 -  ",
      // },
      {
        title: 'Cena en el restaurante "La Vieja Estación" ',
        desc: "",
        speaker: "Todos",
        startTime: "20:00 ",
        timeDetails: " 20:00 -  ",
      },
    ],
  },
  {
    title: "Miércoles, 16 de Noviembre ",
    events: [
      {
        title: "Bienvenida, Agenda",
        desc: "",
        speaker: "Grace Alvarez",
        startTime: "8:15",
        timeDetails: " 8:15  - 8:25  ",
      },
      {
        title: "Generación Distribuida - SGT800 ",
        desc: "",
        speaker: "Miguel Márquez Espínola",
        startTime: "8:25 ",
        timeDetails: "8:25  - 10:25 ",
        expand: true,
        moreDetails: (
          <>
            La turbina de gas industrial SGT-800 combina un diseño simple y
            robusto para alta confiabilidad y fácil mantenimiento, con alta
            eficiencia y bajas emisiones. La evolución progresiva de la SGT-800
            basada en la experiencia de la flota, análisis de diseño, productos
            de mejora y soluciones de servicio, hace que sea un producto
            completo para generación eléctrica y cogeneración.
            <br /> La capacidad de uso de combustibles verdes como parte de la
            fuente de combustible dentro de la turbina nos ubica en un camino
            correcto para lograr nuestro objetivo de cero emisiones netas previo
            a 2030.
          </>
        ),
      },
      {
        title: "Espacio para Café ",
        desc: "",
        speaker: "Todos",
        startTime: "10:30 ",
        timeDetails: "10:30  - 10:50 ",
      },
      {
        title: "Soporte Técnico Latinoamérica",
        desc: "",
        speaker: " Martín Nivela",
        startTime: "10:50 ",
        timeDetails: "10:50  - 11:30 ",
        expand: true,
        moreDetails: (
          <>
            Como propuesta de mejora de nuestros servicios, priorizando la
            cercanía con nuestros clientes, hemos creado el departamento de
            soporte técnico para la región Latinoamérica. El mismo cubre toda la
            línea de productos de turbinas de gas, turbinas de vapor y
            compresores. Dentro de nuestros servicios se incluye el sistema de
            monitoreo de vuestros equipos; el cual comprende toda la flota de
            unidades de generación, así como las recientemente incorporadas
            SGT800, realizado desde nuestras oficinas en Buenos Aires. Durante
            esta presentación, le mostraremos cómo buscamos brindar un soporte
            proactivo y cercano a nuestros clientes.
          </>
        ),
      },
      {
        title: "Operaciones en Latinoamérica: Capacidad y Soluciones",
        desc: "",
        speaker: "Joseph Dodd",
        startTime: "11:30 ",
        timeDetails: "11:30  - 12:15 ",
        expand: true,
        moreDetails: (
          <>
            En Siemens Energy reconocemos los requerimientos y necesidades
            operativas de nuestros clientes, a través de una historia les
            invitamos a conocer las actividades en las estamos involucrados, qué
            hacemos y cómo lo hacemos. Conocerán algunos datos interesantes
            sobre nuestras operaciones y principalmente qué estamos haciendo
            para estar cerca de Ustedes, nuestros clientes.
          </>
        ),
      },
      {
        title: "Marcaciones Finales",
        desc: "",
        speaker: "Joe Matos, Fernando Caffarello, y Gelvis Valdez",
        startTime: "12:15 ",
        timeDetails: "12:15  - 12:45 ",
      },
      {
        title: "Almuerzo",
        desc: "",
        startTime: "12:45 ",
        timeDetails: "12:45  ",
      },
      {
        title: "Despedida",
        desc: "",
        startTime: "14:00 ",
        timeDetails: "14:00 -   ",
      },
    ],
  },
];

export default function Agenda() {
  const theme = useTheme();

  let isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

  function widthView() {
    if (isMobileView) {
      return window.width;
    }
    return 360;
  }

  let viewW = widthView();

  function heightView() {
    if (isMobileView) {
      return window.height - 50;
    }
    return 300;
  }

  let viewH = heightView();

  return (
    <Box>
      <Grid
        container
        direction="row"
        padding={2}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h5" color="#1B1534">
            Agenda
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              window.location.reload();
            }}
          >
            {" "}
            <RefreshIcon />{" "}
          </IconButton>
        </Grid>
      </Grid>
     

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {agendaData.map((day, idx) => (
          <li key={`section-${idx}`}>
            <ul>
              <ListSubheader>{day.title}</ListSubheader>
              {day.events.map((event, idx) => (
                <>
                  <Grid container direction="row" justifyContent="flex-start">
                    <Divider />
                    <ListItem key={`event-${idx}`}>
                      {event.expand ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                        >
                          <Grid item xs={2}>
                            <ListItemText primary={event.startTime} />
                          </Grid>
                          <Grid item xs={10}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                            >
                              <Accordion elevation={0} spacing={0} padding={0}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Grid container direction="column">
                                    <Grid item>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          fontSize: "20px",
                                        }}
                                        primary={event.title}
                                      />
                                    </Grid>
                                    {event.speaker ? (
                                      <Grid item>
                                        <ListItemText
                                          sx={{ fontSize: 8 }}
                                          primary={event.speaker}
                                        />
                                      </Grid>
                                    ) : null}
                                    <Grid item>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          fontSize: "14px",
                                        }}
                                        primary={event.timeDetails}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container justifyContent="flex-start">
                                    <Grid item xs={12}>
                                      <Typography>
                                        {event.moreDetails}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={2}>
                            <ListItemText primary={event.startTime} />
                          </Grid>
                          <Grid item xs={10}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                            >
                              <Grid item>
                                <ListItemText
                                  primaryTypographyProps={{ fontSize: "20px" }}
                                  primary={event.title}
                                />
                              </Grid>
                              {event.speaker ? (
                                <Grid item>
                                  <ListItemText
                                    sx={{ fontSize: 8 }}
                                    primary={event.speaker}
                                  />
                                </Grid>
                              ) : null}
                              <Grid item>
                                <ListItemText
                                  primaryTypographyProps={{ fontSize: "14px" }}
                                  primary={event.timeDetails}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </ListItem>
                  </Grid>
                </>
              ))}
            </ul>
          </li>
        ))}
        <Divider />
      </List>
    </Box>
  );
}
