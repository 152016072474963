import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapPage from "./mapPage";

const tabs = [
  {
    title: "Información General",
    content: (
      <>
        <>
          {" "}
          Joe Matos y Grace Alvarez presentan al nuevo Senior Vicepresidente de
          Servicios para Latinoamérica, Fadi Ghantous, y junto al equipo de
          Siemens Energy Gas Services Latinoamérica lo invitan a vivir la
          experiencia <b>IPOS Plus 2022</b>. <br />
          <br />
        </>

        <>
          Exploraremos las novedades tecnológicas que Siemens Energy ofrece para
          sus equipos, las ventajas y desafíos en Hidrógeno, almacenamiento de
          Energía, transición energética, entre otros temas que serán de su
          interés. <br />
          <br />
        </>

        <>
          En esta ocasión, tendremos un invitado especial: Juan José Aranguren,
          quién expondrá las novedades y tendencias del mercado eléctrico y
          precisamente la transición energética en Latinoamérica con foco
          principal en Argentina. También nos hablará de las expectativas en
          materia de minería y litio.
          <br />
          <br />
        </>

        <>
          Un evento cuidadosamente pensado para que lo disfrute día a día desde
          el lunes 14 de noviembre a partir del mediodía, martes 15 y miércoles
          16 hasta el mediodía, en el hotel Sheraton de la ciudad de Salta,
          provincia de Salta, Argentina. <br />
          <br />
        </>
      </>
    ),
  },
  {
    title: "Código de Vestimenta",
    content: (
      <>
        {" "}
        El código de vestimenta será casual y/o business casual.
        <br /><br />El día lunes 14/11 tendremos una actividad social, por lo que, los invitados 
        pueden concurrir con vestimenta casual. Los días 15 y 16/11, el dress-code es business casual.
      </>
    ),
  },
  {
    title: "Adaptadores / Frequencia y Voltages",
    content: (
      <>
        {" "}
        En Argentina el <b>voltaje es 220 V. La frecuencia es 50 Hz.</b> Las clavijas y enchufes son del tipo C / I.
      </>
    ),
  },
  {
    title: "Clima",
    content: (
      <>
        {" "}
        Durante la <b>primavera</b> empieza a sentirse el calor en Salta, especialmente durante 
        las horas de sol. En ese sentido, es conveniente <b>llevar prendas de vestir sueltas 
        y frescas</b> (ligeras); preferiblemente de colores en tonos claros. Sin embargo, por 
        las noches refresca y es indispensable tener <b>buen abrigo</b>.
        <br /><br />Las temperaturas máximas diarias son alrededor de 27 °C (80 °F) y las temperaturas mínimas diarias pueden ir desde los 2 °C aumentando hasta los 14 °C a 16 °C (60 °F).
      </>
    ),
  },
  {
    title: "Taxi/Remise",
    content: (
      <>
        {" "}
        Remises de Salta <br />
        +54 387 485-3723<br /><br />

        Remises Reyes Católicos<br />
        +54 387 439-8888<br /><br />

        Profesional Remis Fe S.R.L<br />
        +54 387 431-8888<br /><br />

        Taxitel<br />
        +54 387 423-2314<br /><br />
      </>
    ),
  },
  {
    title :"Sitios de Interés",
    content: (
      <MapPage />
    ),
  },
];

export default function SimpleAccordion() {
  return (
    <>
      {tabs.map((tab) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">{tab.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{tab.content}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
