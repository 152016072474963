import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Placeholder from "../assets/images/placeholder.png";

export default function Presentation(props) {
  const [imgSize, setImgSize] = React.useState("300");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (isMobile) {
      setImgSize("300");
    } else if (isDesktop) {
      setImgSize("450");
    } else setImgSize("500");
  }, [isMobile, isDesktop]);

  const item = {
    display: "flex",
    flexDirection: "column",
    px: 5,
  };
  return (
    <>
      <Box
        component="section"
        sx={{
          flexDirection: "column",
          display: "flex",
          overflow: "hidden",
          mt: 5,
          mb: 5,
        }}
      >
        <Container
          sx={{
            display: "flex",
            position: "relative",
            bgcolor: props.color,
            p: 5,
          }}
        >
            <Grid container spacing={2}  direction={props.side === "left" ? "row" : "row-reverse"}>
              <Grid item xs={12} lg={5} align="center" >
                <img width={imgSize} src={props.image} />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Grid container>
                  <Grid item>
                  <Typography
                      variant="h6"
                      sx={{
                        mt: { xs: 3, sm: 0 },
                        color: props.textColor,
                      }}
                    >
                      {props.title}
                    </Typography>
                    <Typography  sx={{ mb: 4, color: props.textColor }}>
                      {props.author}
                    </Typography>
                    
                    <Typography sx={{ mb: 4, color: props.textColor }}>
                      {props.content}
                    </Typography>
                      <Grid item>
                          <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={props.link}
                            underline="none"
                            color={props.textColor}
                          >
                            <Button
                              variant="outlined"
                              startIcon={<PDFIcon />}
                              color="inherit"
                              sx={{ borderRadius: 8 }}
                            >
                              Descargar Presentación
                            </Button>
                          </Link>
                        </Grid>
                  </Grid>
                
                </Grid>
              </Grid>
            </Grid>
        </Container>
      </Box>
    </>
  );
}
