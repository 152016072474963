import Map from "./map";

import { Container, Grid, Box, Typography } from "@mui/material";
import SitesCard from "../components/sitesCard";

const markers = [
  {
    location: "Sheraton Salta Hotel",
    lat: -24.785367777503,
    lng: -65.39821179903994,
    link: "https://goo.gl/maps/6WGMPDeBVoD13ufa6",
    title: "Sheraton Salta Hotel",
  },
  {
    location: "Adobe Cocina Regional",
    lat: -24.789893890805807,
    lng: -65.40984568590184,
    link: "https://goo.gl/maps/NYGJyj2mYh6EbhZ48",
    title: "Adobe Cocina Regional",
  },
  {
    location: "La Vieja Estación",
    lat: -24.779022645480772,
    lng: -65.4115366243,
    link: "https://goo.gl/maps/ZRsbXttzHz9WTd1x8",
    title: "La Vieja Estación",
  },
  {
    location: "Hacienda Cerdo Negro",
    lat: -24.893142200673722,
    lng: -65.53325841960418,
    link: "https://goo.gl/maps/ipXSQUKLkWYviHCBA",
    title: "Hacienda Cerdo Negro",
    
  },
];

export default function MapPage() {
  return (
    <Container sx={{ display: "flex", position: "relative" }} >
      <Grid container >
        <Grid item>
          <Box>
            {/* <Typography
              variant="h5"
              sx={{ mb: 4, mt: 7, fontWeight: "bold", color: "#1B1534" }}
            >
              Sitios de Interés
            </Typography> */}
            <Typography
              variant="subtitle1"
              sx={{ mb: 4, mt: 3, color: "#1B1534" }}
            >
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ align: "center", width: "100%" }} padding={2} justifyContent="flex-start">
          <Grid container>
            <Grid item xs={12} align="center" sx={{mt: 5}}>
              <Map
                markers={markers}
                lat={-24.785367777503}
                lng={-65.39821179903994}
              />
            </Grid>
            <Grid item xs ={12} >
              <SitesCard/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
