import Presentation from "../components/presentation";


import Luna from "../assets/images/presentations/Luna.png";
import Colmenares from "../assets/images/presentations/Colmenares.png";
import Dodd from "../assets/images/presentations/Dodd.png";
import Marquez from "../assets/images/presentations/Marquez.png";
import Nivela from "../assets/images/presentations/Nivela.png";
import Sandhaas from "../assets/images/presentations/Sandhaas.png";
import Grace from "../assets/images/presentations/Grace.png";
import Fadi from "../assets/images/presentations/Fadi.png";
import Aranguren from "../assets/images/presentations/Aranguren.png";

export default function Presentations() {
  return (
    <>
      <Presentation
        image={Grace}
        title="Bienvenida e Introducción"
        content={
        <>
          Martes, 15 de Noviembre<br />
          8:25 - 9:00.<br />
        </>
        }
        link ="/pdfs/Grace.pdf"
        
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Grace Alvarez"
      />
      <Presentation
        image={Fadi}
        title="Siemens Energy | Gas Services"
        content={
        <>
          Martes, 15 de Noviembre<br />
          9:00 - 9:30.<br />
        </>
        }
        link ="/pdfs/Fadi.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Fadi Ghantous"
      />
      <Presentation
        image={Colmenares}
        title="Nuevos desafíos: Hidrógeno, Almacenamiento de Energía, Compensador Síncrono"
        content={
        <>
          Martes, 15 de Noviembre<br />
          9:30 - 10:45.<br />
        </>
        }
        link ="/pdfs/Colmenares.pdf"
        
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Pablo Colmenares"
      />
      <Presentation
        image={Sandhaas}
        title="Flota  - SGT5-4000F/SGT5-2000E: Novedades"
        content={
        <>
          Martes, 15 de Noviembre<br />
          11:00 - 11:45.<br />
        </>
        }
        link ="/pdfs/Sandhaas.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Steven Sandhaas"
      />
      <Presentation
        image={Aranguren}
        title="Mercado Eléctrico en Argentina"
        content={
        <>
          Martes, 15 de Noviembre<br />
          13:40 -15:40.<br />
        </>
        }
        link ="/pdfs/Aranguren.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Juan José Aranguren"
      />
      <Presentation
        image={Luna}
        title="Modelos Avanzados - SGT 9000HL"
        content={
         <>
          Martes, 15 de Noviembre <br />
          16:00  - 17:15.<br />
          </>
        }
        link ="/pdfs/Luna.pdf"
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Jorge Luna"
      />
      <Presentation
        image={Marquez}
        title="Generación Distribuida - SGT800"
        content={
        <>
          Miércoles, 16 de Noviembre<br />
          8:25  - 10:25 .<br />
        </>
        }
        link ="/pdfs/Marquez.pdf"
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Miguel Márquez Espínola"
      />
      <Presentation
        image={Nivela}
        title="Soporte Técnico Latinoamérica"
        content={
        <>
          Miércoles, 16 de Noviembre<br />
          10:50  - 11:30.<br />
        </>
        }
        link ="/pdfs/Nivela.pdf"
        
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Martín Nivela"
      />
      <Presentation
        image={Dodd}
        title="Operaciones en Latinoamérica: Capacidad y Soluciones"
        content={
        <>
          Miércoles, 16 de Noviembre<br />
          11:30 - 12:15.<br />
        </>
        }
        link ="/pdfs/Dodd.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Joseph Dodd"
      />
      
    </>
  );
}
