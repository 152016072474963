import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SELogo from "../assets/images/logo.svg";
import BackButton from "./backbutton";
import { useLocation } from "react-router-dom";

const noBackButtonList = ["/home", "/", "/thanks"];

export default function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="fixed" sx={{ bgcolor: "#1B1534" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>

          {!noBackButtonList.includes(useLocation().pathname) ? (
            <>
              <BackButton />
              <Box sx={{ mt: 2, mb: 2, justifyContent: "end" }}>
                <img height={40} src={SELogo} alt="Siemens Energy" />
              </Box>
            </>
          ) : (
            <>
            <div> </div>
            <Box sx={{ mt: 2, mb: 2, justifyContent: "right" }}>
              <img height={40} src={SELogo} alt="Siemens Energy" />
            </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" sx={{ justifyContent: "space-between" }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <img height={40} src={SELogo} alt="Siemens Energy" />
        </Box>
      </Toolbar>
    </Box>
  );
}
