import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import NavBar from "./components/navbar";
import Agenda from "./pages/agenda";
import Attendees from "./pages/attendees";
import Emergency from "./pages/emergency";
import FlightHotelInfo from "./pages/flighthotelinfo";
import Guest from "./pages/guest";
import Home from "./pages/homepage";
import Info from "./pages/info";
import Map from "./pages/map";
import Register from "./pages/register";
import Speakers from "./pages/speakers";
import Survey from "./pages/survey";
import Trivia from "./pages/trivia";
import Thanks from "./pages/thanks";
import Products from "./components/products";
import SingleInfo from "./components/singleInfo";
import AppFooter from "./components/footer";
import { Box } from "@mui/material";
import MapPage from "./pages/mapPage";
import axios from "axios";
import { useEffect } from "react";
import AdminPage from "./pages/admin";
import Presentations from "./pages/presentations";
import Gallery from "./pages/gallery";
import Organizers from "./pages/organizers";

import TagManager from 'react-gtm-module'
import NotAvailable from "./components/notAvailable";

const tagManagerArgs = {
    gtmId: 'G-K0SPHBNVFK'
}

const noNavBar = ["/", "/register"];

function App() {
  // TODO: Add Theme Provider
  // TODO route the pages
  axios.defaults.baseURL = "https://se-conference-app.herokuapp.com/";

  TagManager.initialize(tagManagerArgs)
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: window.location.href,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location]);


  // useEffect(()=> {

  //   axios.defaults.baseURL =""; 
  //   axios.get("https://us-central1-template-conapp.cloudfunctions.net/app/helloWorld")
  //   .then(function(response) {
  //     const data = response.data; 
  //     console.log(JSON.stringify(data)); 
  //     axios.defaults.baseURL = "https://se-conference-app.herokuapp.com/";
  //   })
    
  // }, [])

  return (
    <>
      <Box
        sx={{
          height: "100%",
          minHeight: "100vh",
        }}
      >
        {!noNavBar.includes(useLocation().pathname) ? <NavBar /> : null}

        <Routes>
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/expositores" element={<Speakers />} />
          <Route path="/participantes" element={<Attendees />} />
          <Route path="/info" element={<Info />} />
          <Route path="/invitadoespecial" element={<Guest />} />
          <Route path="/hotelinfo" element={<FlightHotelInfo />} />
          {/* <Route path="/encuesta" element={<Survey surveyId={2} />} />
          <Route path="/voicematters" element={<Survey surveyId={3} />} /> */}
          
          <Route path="/encuesta" element={<NotAvailable/>} />
          <Route path="/voicematters" element={<NotAvailable/>} />

          <Route path="/register" element={<Register />} />
          <Route path="/thanks" element={<Thanks />} />
          {/* <Route path="/admin" element={<AdminPage />} /> */}
          <Route path="/organizadores" element={<Organizers />} />
          {/* <Route path="/galeria" element={<Gallery />} /> */}
          <Route path="/presentaciones" element={<Presentations />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Box>

      <Box>
        <AppFooter />
      </Box>
    </>
  );
}
export default App;
