import HotelCard from "../components/hotelCard"
import FlightCard from "../components/flightCard"
import Map from "./map"
import {Box} from "@mui/material"



const markers = [  {
    location: "Sheraton Salta Hotel",
    lat: -24.785367777503,
    lng: -65.39821179903994,
    link: "https://goo.gl/maps/6WGMPDeBVoD13ufa6"
  },]; 


export default function FlightHotelInfo() {
    return (
        <>
        
        <HotelCard/>
        <Box align="center">
        <Map markers={markers}  lat={-24.785367777503}  lng={-65.39821179903994}/>
        </Box>
        </>
    )
}
