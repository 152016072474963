import { Link, Box, Container, Grid, Typography } from "@mui/material";

import LatinGlobe from "../../src/assets/images/latinamerica.gif";
import IPOSQRCode from "../../src/assets/images/IPOSQRCode.png";

export default function Thanks() {
  return (
    <>
    <Box >
      <img
          width='100%'
          height='auto'
          src={LatinGlobe}
          alt="IPOS"
      />
    </Box>
    <Container sx={{ display: "flex", position: "relative" }} padding={3} align="center">
      <Grid container direction="column" align="center">
        <Typography sx={{ mt:4 }} variant="h3">¡Registro exitoso!</Typography>
         <Grid container spacing={2} >
          <Grid item   >
            <Box align="center">
              
              <Typography
                variant="h5"
                sx={{ mb: 4, mt: 7, fontWeight: "bold", color: "#1B1534" }}
              >
              
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ mb: 4, mt: 3, color: "#1B1534" }}
              >
                Sin embargo, todavía falta que asegure su estadía en el hotel <b>Sheraton Salta.</b> <br /><br />
                Por favor, envíe un correo electrónico a Antonella Sanchez Sayago 
                (<Link href="mailto:antonella.sanchezsayago@sheraton.com?subject=Siemens Energy IPOS Plus 2022">antonella.sanchezsayago@sheraton.com</Link>) 
                con el asunto <b>"Siemens Energy IPOS Plus 2022"</b> solicitando cotización con nuestra tarifa especial para la estadía e instrucciones de pago.  
                <b>Recuerde que tenemos cupos limitados.</b><br /><br />
                Lo invitamos a bajar los detalles de la agenda, escaneando el código QR que aparece acá abajo, con su teléfono móvil, tableta, o solo haciendo <Link href="/">click aquí</Link>
              </Typography>
              <img src={IPOSQRCode} width="150" height="147" alt="IPOS 2022 QR" /><br /><br />
            </Box>
          </Grid>
        </Grid> 
        
      </Grid>
    </Container>
    </>
  );
}
