import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hotel from "../assets/images/hotel.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const sites = [
  {
    location: " Sheraton Salta Hotel",
    address: "Coronel Francisco de Uriondo 330, Salta A4406BKQ Argentina ",
    link: "https://goo.gl/maps/6WGMPDeBVoD13ufa6",
  },
  {
    location: "Adobe Cocina Regional",
    address: "Caseros 511 Primer Piso, A4400 Salta, Argentina",
    link: "https://goo.gl/maps/NYGJyj2mYh6EbhZ48",
  },
  {
    location: "La Vieja Estación",
    address: "Balcarce 875, A4400 Salta, Argentina",
    link: "https://goo.gl/maps/ZRsbXttzHz9WTd1x8",
  },
  {
    location: "Hacienda Cerdo Negro",
    address:
      "RP 24 km 5,5 Finca La Montanera, A4403 Cerrillos, Salta, Argentina",
      link: "https://goo.gl/maps/ipXSQUKLkWYviHCBA",
  },
];

export default function SitesCard() {
  const [imgSize, setImgSize] = React.useState("300");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (isMobile) {
      setImgSize("300");
    } else if (isDesktop) {
      setImgSize("450");
    } else setImgSize("500");
  }, [isMobile, isDesktop]);

  const item = {
    display: "flex",
    flexDirection: "column",
    px: 5,
  };
  return (
    <>
      <Box
        component="section"
        sx={{
          flexDirection: "column",
          display: "flex",
          overflow: "hidden",
          mt: 5,
          mb: 5,
        }}
      >
        <Container
          sx={{
            display: "flex",
            position: "relative",
            bgcolor: "#1B1534",
            p: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={3}>
                {sites.map((site) => {
                  return (
                    <Grid item align="center" xs ={12} sm ={6}>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, mt: { xs: 3, sm: 0 }, color: "white" }}
                      >
                        {site.location}
                      </Typography>
                      <br />
                      <Grid
                        container
                        direction="row"
                        spacing={3}
                        sx={{ mb: 3, color: "white" }}
                      >
                        <Grid item >
                          <LocationOnIcon />
                        </Grid>
                        <Grid item xs={8}>
                          <Link href={site.link}>
                          <Typography variant="body1">
                            {site.address}
                            <br />
                          </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
