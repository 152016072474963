import * as React from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Box,
  Typography,
  Container,
  Link,
  Collapse,
  IconButton,
} from "@mui/material";
import LinkedIn from "../assets/images/linkedinDark.svg";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Placeholder from "../assets/images/placeholder.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { List, ListItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Fadi from "../assets/images/Bios/Fadi.png";
import Grace from "../assets/images/Bios/Grace.jpg";
//import Erich from "../assets/images/Bios/Erich.jpg";
import Jorge from "../assets/images/Bios/jorgeluna.png";
import Joseph from "../assets/images/Bios/Joseph.jpg";
import Steven from "../assets/images/Bios/Steven.jpg";
import Pablo from "../assets/images/Bios/Pablo.jpg";
import Joe from "../assets/images/Bios/Joe.jpg";
import Sebastian from "../assets/images/Bios/Sebastian.jpg";
import Miguel from "../assets/images/Bios/Miguel.jpg";
import Martin from "../assets/images/Bios/Martin.jpg";
import Fernando from "../assets/images/Bios/Fernando.jpg";

const iconStyle = {
  width: 60,
  height: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
  fill: "#0072ea",
};

const Anfitrion = [
  {
    name: "Joe Matos",
    email: "joe.matos@siemens-energy.com",
    position: <>Director de Ventas – Servicios para Generación</>,
    image: Joe,
    linkedIn: "https://www.linkedin.com/in/joe-matos-821314",
    bullets: [
      "27 años en la industria de la energía, gestionando y liderando negociaciones en Latinoamérica. Especializado en turbinas de Gas y de Vapor.",
      "8 años en Diseño de ingeniería, y 35 años en el la industria de Generación y Aviación con las empresas Jet Propulsion Laboratory, General Electric Aircraft Engines, Westinghouse y Siemens AG/Siemens Energy.",
      "Joe es Licenciado en Ingeniería Mecánica del Rensselaer Polytechnic Institute, Troy NY, USA. Con una Maestría en Administración de Empresas (MBA) – del Florida Institute of Technology,  Melbourne Florida, USA. ",
      "Nacido y criado en la ciudad de Nueva York, USA. Actualmente reside en Orlando, FL.",
      "Joe es Fanático del beisbol y futbol Americano, y su pasión mas reciente: Es Abuelo!",
    ],
  },
  {
    name: "Fernando Caffarello",
    email: "fernando.caffarello@siemens-energy.com",
    position: <>Sales Distribuited Head</>,
    image: Fernando,
    linkedIn: "https://ar.linkedin.com/in/fernando-alberto-caffarello-0314922b/en",
    bullets: [
      "Responsable de departamento de ventas de servicios para división de generación distribuida en Latinoamérica Sur. Con foco en segmentos generación, Industria y gas y petróleo.",
      "Desarrollador y responsable por la nueva oficina de Siemens Energy en Neuquen (Vaca Muerta)",
      "20 años de experiencia en el mercado energético regional, generación eléctrica y mercado de gas y petróleo. Gestión de los contratos relacionados con flota SGT-800 en Argentina.",
      "Gerente de planta El Tordillo, Tecpetrol- Chubut Argentina.  Power Generation con SGT-100 en aplicación de O&G",
      "Fernando es Ingeniero Mecánico de la Universidad Marina Mercante de Buenos Aires, y un MBA de la Universidad Austral SJ Bosco también de Buenos Aires.",
      "En su tiempo libre le gusta correr, hacer ciclismo y viajar.",
    ],
  },
  {
    name: "Sebastian Diaz Gallardo",
    email: "sebastian.diaz@siemens-energy.com",
    position: <>South Andina Head of Sales</>,
    image: Sebastian,
    linkedIn: "https://ar.linkedin.com/in/sebastian-diaz-gallardo-a0671632",
    bullets: [
      "Responsable de Ventas para la Región Andina Sur en el área de Servicios de Generación de Energía.",
      "17 años en el área de Energía, comenzó en el área de finanzas en una distribuidora de Gas, luego ingresó en Siemens hace 15 años cómo comercial de proyectos, apoyando en la ejecución de contratos a largo plazo en el país, luego paso al área de ventas  liderando las más importantes negociaciones con clientes de la región en los últimos 10 años.",
      "Graduado de la Universidad Argentina de la empresa, Buenos Aires, Facultad de Administracion de empresas.",
      "Bilingüe, ingles y español fluidos.",
      "Casado y padre de  2 bellas hijas.",
      "Nacido en Santiago del Estero y apasionado de los deportes, exjugador de Volleyball profesional. Le gusta viajar y conocer nuevas culturas.",
    ],
  },
];

const sample = [
  {
    name: "Fadi Ghantous",
    email: "fadi.ghantous@siemens-energy.com",
    position: <>Senior VP de Servicios para la Región de Latinoamérica</>,
    image: Fadi,
    linkedIn: "https://de.linkedin.com/in/fadi-a-ghantous-b46598",
    bullets: [
      "Fadi es ciudadano libanés y francés. Fue designado como Vicepresidente  de Gas Servicios para Latin America el 1 de octubre de 2022. Antes de este nombramiento, fue VP Senior de Ventas de la antigua División de Generación de Siemens Energy, desde el 1 de abril de 2020.",
      "Su carrera en Siemens comenzó en 2017, liderando el equipo global de Bid Management del negocio Turnkey-Solutions para plantas de energía de gas y vapor,  con servicios en EEUU., Alemania, Austria, Dubai, Singapur y Corea.",
      "Antes de Siemens, estuvo empleado por ALSTOM/GE durante más de 14  años en T&D y el sector de la energía, gestionando negocios y funciones globales con servicio en varios países (Francia, EEUU, Alemania, México, India y China. Entre 2009 y 2014, estuvo basado en México, impulsando el negocio de Grid para América Latina. Durante este tiempo, tuvo el placer de vivir, aprender y apreciar la cultura latinoamericana. ",
      "Fadi es ingeniero eléctrico de la Universidad Americana de Beirut, con maestría de la escuela de negocios francesa ESCP en París, y un título ejecutivo (AMP) de Harvard Business School en EE.UU. Es miembro del directorio de Siemens Energy AB (Suecia) desde 2020 y miembro del directorio de VDMA (Asociación Alemana de Construcción y Centrales Eléctricas) desde 2017.",
      "Habla inglés, francés, español y árabe con fluidez. En su tiempo libre, disfruta de un estilo de vida activo, particularmente andar en bicicleta y jugar baloncesto.",
    ],
  },
  {
    name: "Grace Alvarez",
    email: "grace.alvarez@siemens-energy.com",
    position: (
      <>
        Director de Estrategia, Desarrollo de Negocios y Excelencia para el área
        de Servicios de Generación de Energía e Industrias
      </>
    ),
    image: Grace,
    linkedIn:
      "https://www.linkedin.com/in/e-graciela-grace-alvarez-mba-8149bab",
    bullets: [
      "Director de Estrategia, Desarrollo de Negocios y Excelencia para el área de Servicios de Generación de Energía e Industrias incluyendo Gas & Petróleo para la Región de Latinoamérica. Grace colabora directamente con las áreas de Ventas,  Mercadeo, Operaciones, Finanzas y Digitalización, entre otros, acompañando a los clientes desarrollando una mayor eficiencia en sus procesos, incluyendo optimización en la operación de sus equipos.",
      "20 años en el área de Energía liderando negociaciones internacionales en más de 20 países. Comenzó en la Sucursal de Siemens Energy de Argentina, siguieron 6 años en la casa matriz de Alemania y actualmente lleva más de 12 en Florida, Estados Unidos lo que le han dado una variada y nutrida experiencia en el campo.",
      "Grace es graduada de la Universidad de Buenos Aires, Facultad de Derecho en Argentina con un Master en Negocios de Crummer Business School de la Universidad de Rollins, Florida, Estados Unidos",
      "Habla fluido Español, Alemán e Inglés",
      "Fotografía es uno de sus pasiones así como también varios deportes: senderismo de montaña, ciclismo y natación.",
    ],
  },
  {
    name: "Pablo Colmenares",
    email: "pablo.colmenares@siemens-energy.com",
    position: <>Mod & Ups Marketing Lead</>,
    image: Pablo,
    linkedIn: "https://co.linkedin.com/in/pablo-colmenares-87148b1a2",
    bullets: [
      "Leader de Mercadeo, Modernización y Mejoras de Turbinas de Combustión para el área de servicio de la Región Norteamérica.",
      "Pablo cuenta con 24 años de experiencia profesional y gerencial, de los cuales 14 años han sido en la industria de generación de electricidad en Siemens Energy.",
      "Antes de trabajar en el área de ventas, Pablo trabajo en el departamento de Ingeniería de Turbinas de Combustión para el área de servicios dando soporte técnico a las Regiones de Latinoamérica, Norteamérica, el Medio Oriente y Asia.",
      "Pablo es Ingeniero graduado en la Universidad Rafael Urdaneta en Venezuela y posee Maestrías en Ingeniería de la Universidad de New Orleans (New Orleans, Estados Unidos), y Administración de Empresas de la Universidad de la Florida Central (UCF- Orlando, FL. Estados Unidos)",
      "Reside en la ciudad de Orlando, y en su tiempo libre disfruta el ciclismo, correr y pasar tiempo con su esposa y dos hijos.",
    ],
  },
  {
    name: "Steven Sandhaas",
    email: "joseph.dodd@siemens-energy.com",
    position: <>Service Engineer</>,
    image: Steven,
    linkedIn: "https://www.linkedin.com/in/steven-sandhaas-206651103",
    bullets: [
      "Apoyando el área de Servicios de Generación de Energía especialmente turbinas de gas en las Americas, Steven trabaja en conjunto con el área de ventas, servicios y de implementación en los diferentes países de éstas regiones. También trabaja con los departamentos de ingeniería de servicios, desarrollo de tecnología y ventas entre otros, en las casas matrices en Alemania y EEUU.  Siempre buscando junto a los clientes encontrar soluciones para mejorar la eficiencia y potencia de sus termoeléctricas, aumentar la disponibilidad y confiabilidad además de apoyar en la solución de problemas técnicos.",
      "Cuenta con 15 años de  experiencia en turbinas de gas alrededor del mundo, principalmente en las Américas y Europa, en el área de desarrollo de nuevos productos y soluciones, análisis de causa raíz, apoyando negociaciones y liderando implementaciones de mejoras y cambios de maquinas completas (BEX – Brownfield Engine Exchange). Comenzó su carrera en Siemens en Muelheim, Alemania. Fué delegado a Buenos Aires por 5 años para formar y desarrollar la ingeniería de servicios local para Latinoamérica. Desde hace 6 años trabaja desde Orlando, Florida, EEUU",
      "Steven es graduado en la facultad de Ingeniería Industrial de la Universidad de Kaiserslautern, en Alemania (Dipl.-Wirtsch.-Ing.). También cuenta con  una Maestría en Ciencias de Industria Energética RWTH Aachen / Universidad Muenster, Alemania.",
      "Le encanta la cultura Latina. En su tiempo libre le gusta correr, senderismo en la naturaleza y disfrutar de un buen vino. Habla Alemán, Inglés y Español.",
    ],
  },
  {
    name: "Jorge Luna",
    email: "jorge.luna@siemens-energy.com",
    position: <>HL Business Development</>,
    image: Jorge,
    linkedIn: "https://www.linkedin.com/in/jorgeluis-luna",
    bullets: [
      "Jorge es responsable por el desarrollo de mercado para Turbinas de clase avanzada HL con enfoque global.  Apoya directamente a los equipos de ventas,  mercadeo y finanzas para optimizar ofertas y desarrollar proyectos con tecnología avanzada HL. Su interacción con clientes se enfoca en el desarrollando de su flota de energía, guiándolos a la descarbonización con tecnologías avanzadas y eficientes.",
      "16 años en el área de Energía e ingeniería, ejecución de proyectos, puesta en marcha, gerente de propuestas y recientemente con enfoque en el desarrollo de mercado de Turbinas de clase avanzada HL. ",
      "Ha colaborado en mega-deals y ordenes importantes para Siemens con tecnologías 5000F, 8000H y 9000HL. Con interfaces directas a clientes internacionales, primordialmente en el mercado de América Latina",
      "Jorge es graduado en Ingeniera Eléctrica de la Universidad de Central Florida, UCF en Orlando FL. USA con una maestría en administración de negocios.",
      "Habla fluido Español e Inglés.",
    ],
  },
  {
    name: "Miguel Márquez Espínola",
    email: "miguel.marquez_espinola@siemens-energy.com",
    position: <>Medium sized gas turbines Commissioning Engineer</>,
    image: Miguel,
    linkedIn: "https://se.linkedin.com/in/miguelmarquezespinola",
    bullets: [
      "Actualmente se desempeña como Ingeniero de comisionamiento para turbinas de gas dentro de nuevas instalaciones. Responsable de pruebas de comisionamiento en frio de turbinas SGT-600, SGT-700, SGT-750 y SGT-800 en el centro de pruebas de Finspång,Sweeden.",
      "Se unió a Siemens Energy Suecia en 2017 como ingeniero de aplicación para mercados en Latinoamérica en donde fue responsable de estructurar el alcance para diferentes tipos inspección en nuestros contratos de servicio. Colaboró continuamente con clientes de la región para asegurar los niveles de confiabilidad y disponibilidad de las turbinas de gas de acuerdo a las especificaciones del contrato.",
      "Inició su carrera profesional en el área aeronáutica como jefe de mantenimiento mayor, posición en la que lideró y planifico las actividades realizadas por equipos de especialistas para Airbus A320 y Boeing 737, siguiendo altos estándares de calidad y así asegurar la confiabilidad de las aeronaves.",
      'Miguel es graduado en Ingeniería Eléctrica de la "Universidad Centroamericana, José Simeón Cañas, UCA", El Salvador. Vive en Finspång, Suecia y le gusta realizar senderismo alrededor de bosques y lagos.',
    ],
  },
  {
    name: "Martin Nivela",
    email: "martin.nivela@siemens-energy.com",
    position: <>Gerente de Soporte Técnico para la Región Latinoamérica. </>,
    image: Martin,
    linkedIn: "https://ar.linkedin.com/in/mart%C3%ADn-nivela-043b606a",
    bullets: [
      "Martin ha estado en Siemens Energy durante más de 15 años, sirviendo en funciones que van desde la puesta en marcha de Instrumentación & Control de turbinas de gas y turbinas de vapor, mantenimiento de alcance completo, soporte centralizado e innovación y digitalización y soporte técnico regional.",
      "Martín es Ingeniero en Electrónica de la Universidad de Buenos Aires, Argentina y diplomado en Digitalización de la Universidad Torcuato Di Tella, Argentina. Ha recibido entrenamientos profesionales y en Alemania y Estados Unidos.",
      "Habla Español e Inglés fluido.",
      "En su tiempo libre le gusta la pesca y es aficionado al ferromodelismo. Esta casado con Yésica por 11 años y es padre de Facundo (6) y Tomás (2).",
    ],
  },
  {
    name: "Joseph Dodd",
    email: "joseph.dodd@siemens-energy.com",
    position: <>Service Operation Head</>,
    image: Joseph,
    linkedIn: "https://www.linkedin.com/in/doddjoseph",
    bullets: [
      "Responsable de la implementación de las Operaciones en la región de América Latina, incluyendo los Servicios en Campo, Asesoría Técnica, Centros de Servicio, Talleres de Reparación y la implementación de los contratos de Operación y Mantenimiento de los equipos Siemens Energy y además de otros fabricantes.",
      "Joseph ha contribuido con mas de 25 años en el sector energético con su amplia experiencia en los sectores de Petróleo y Gas, Generación de Energía Central y Distribuida, así como Plantas de Generación Renovables como son Eólicas y Solares; con varias funciones desde Ventas, Implementación de Proyectos, Dirección General, entre otros; además con residencia en varios países, México, Alemania, Sudáfrica, Colombia y Estados Unidos, que durante su trayectoria le han permitido contribuir al crecimiento de la organización mediante la implementación de proyectos de innovación, crecimiento de capacidades y así como la consolidación de las adquisiciones de diversos fabricantes (Alstom Industrial, Rolls Royce Energy, Demag Delaval, Dresser Rand).",
      "Graduado como Ingeniero Mecánico con una especialidad en Energía de la Universidad Autónoma Metropolitana (UAM), y con Diplomados del Instituto Tecnológico Autónomo de México (ITAM) en Gestión Gerencial.",
    ],
  },
  
  
  // {
  //   name: "Erich Angermann",
  //   phone: "+00 000 000 0000",
  //   email: "someone2@siemens-energy.com",
  //   position:
  //     " Gerente de Servicio Región Latinoamérica para Sistemas de Control",
  //   image: Erich,
  //   pdfLink: "/pdfs/Contacts/FernandoCaffarello.pdf",
  //   linkedIn: "",
  //   bullets: [
  //     "Desde 2020 Gerente de Servicio  Región Latinoamérica para Sistemas de Control, Sistemas Eléctricos SFC-SEE, Protecciones de Generadores y Servicios en Digitalización.",
  //     "Gerente del Centro de Entrenamiento TECC para Turbinas a Vapor y Generadores en Mülheim, Alemania desde el 2013 hasta el 2019.",
  //     "Con base en Buenos Aires 8 años de servicio, ejecutando migraciones de Sistemas de Control, implementación de mejoras, puesta en marcha y tuning en Turbinas de Gas en toda la Región Latinoamérica. Luego asumió la función de District Service Manager para el área de Servicios de Generación de Energía en Argentina y Chile por 4 años.",
  //     "Comenzó en la sucursal de Siemens Energy en Erlangen, Alemania y empezó su carera como ingeniero de puesta en marcha para varios Sistemas de Control en Ciclos Combinados en Latinoamérica, Europa, Medio Oriente y Asia. Durante estos 9 primeros años paso por varias áreas funcionales en las Centrales Ciclo Combinados comisionando los Controles y Protecciones de Turbinas a Gas, Turbinas a Vapor, Caldera, Ciclo de Agua y Vapor.",
  //   ],
  // },
];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton aria-label="down" size="large" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Section(contacts, title) {
  return (
    <Grid container spacing={4} sx={{ mt: 1, mb: 5 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ ml: { xs: 3, md: 0 } }}>
          {title}
        </Typography>
      </Grid>
      {contacts.map((contact) => (
        <Grid item key={contact.email} xs={12} sm={6} md={4}>
          <SpeakerCard contact={contact} />
        </Grid>
      ))}
    </Grid>
  );
}

function SpeakerCard({ contact }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 345 }}>
      <CardMedia
        component="img"
        height="350"
        image={contact.image}
        alt={`${contact.name}`}
      />
      <CardContent>
        <Typography variant="h5" component={"h2"}>
          {contact.name}
        </Typography>
        <Typography variant="body1" sx={{ minHeight: 100 }}>
          {contact.position}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container direction="row">
              <Link
                href={`mailto:${contact.email}?subject=Siemens Energy IPOS Plus 2022`}
              >
                <IconButton aria-label="email" size="large">
                  <EmailIcon fontSize="large" />
                </IconButton>
              </Link>
              <Box component="a" href={contact.linkedIn} sx={iconStyle}>
                <img src={LinkedIn} alt="LinkedIn" />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent={"flex-end"}>
              <Button
                sx={{
                  "&:disabled": {
                    color: "black",
                  },
                }}
                disabled
              >
                Ver Más
              </Button>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" />
              </ExpandMore>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              {contact.bullets
                ? contact.bullets.map((bullet) => {
                    return <ListItem>{bullet}</ListItem>;
                  })
                : null}
            </List>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default function Album() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container sx={{ display: "flex", position: "relative" }} padding={3}>
      <Grid container spacing={isMobile ? 0 : 3}>

        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h5"
              sx={{ mb: 4, mt: 7, fontWeight: "bold", color: "#1B1534" }}
            >
              Expositores
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 4, mt: 3, color: "#1B1534" }}
            >
              Conozca quiénes son los disertantes del evento.
            </Typography>
          </Box>
        </Grid>
        <Box align="center">{Section(sample, "")}</Box>

        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h5"
              sx={{ mb: 4, mt: 7, fontWeight: "bold", color: "#1B1534" }}
            >
              Anfitriones
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 4, mt: 3, color: "#1B1534" }}
            >
              Conozca quiénes son los anfitriones del evento.
            </Typography>
          </Box>
        </Grid>
        <Box align="center">{Section(Anfitrion, "")}</Box>

      </Grid>
    </Container>
  );
}
