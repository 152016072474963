import { Box, Grid, Card, CardContent, Typography } from "@mui/material";

export default function NotAvailable() {
  return (
    <Box align="center" padding={3}>
          <Card sx={{ width: 400, height: 100 }}>
            <CardContent>
              <Typography variant="h6">
                The time to complete the surveys has expired.
              </Typography>
            </CardContent>
          </Card>
    </Box>
  );
}
