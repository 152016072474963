import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import BackButton from "../components/backbutton";
import { responsiveFontSizes, useTheme } from "@mui/material/styles";

// MUI Styling
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";

function createData(name, email, company) {
  return { name, email, company };
}

const rows = [
  createData("Christine Stevens", "christine@se.com", "Siemens Energy"),
  createData("Christine Stevens 2", "christine2@se.com", "Siemens Energy"),
  createData("Christine Stevens 3", "christine3@se.com", "Siemens Energy"),
];

export default function NotRegistered() {
  const [attendeesList, setAttendeesList] = useState([]);
  const theme = useTheme();

  let isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

  function widthView() {
    if (isMobileView) {
      return window.width;
    }
    return 360;
  }

  let viewW = widthView();

  function heightView() {
    if (isMobileView) {
      return window.height - 50;
    }
    return 300;
  }

  let viewH = heightView();

  function getAttendees() {
    setAttendeesList([]);

    let list = [];
    axios
      .get("/attendees/notRegistered")
      .then((response) => {
        response.data.forEach((attendee) => {
          list.push(
            createData(attendee.name, attendee.email, attendee.company)
          );
        });

        setAttendeesList(list);
      })
      .catch(function (error) {
        console.log("This is the error in getAttendees: " + error);
      });
  }

  useEffect(() => {
    //getAttendees();
  }, []);

  function BasicTable() {
    return (
      <TableContainer component={Paper}>
        <Table
          sx={{ maxWidth: 650 }}
          justifyContent="flex-start"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }}>Participante</TableCell>
              <TableCell align="left">Compañia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendeesList.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" style={{ width: 200 }}>
                  <Typography>
                    {row.name} <br />
                  </Typography>
                  <Typography>
                    {row.email} <br />
                  </Typography>
                  <Typography variant="subtitle"></Typography>
                </TableCell>
                <TableCell align="left">{row.company}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "scroll" }}>
      <Grid sx={{ maxHeight: 440 }}>
        <Box align="center">
          <Grid container direction="row" padding={2}>
            <Typography variant="h5">Participantes</Typography>
          </Grid>
          <BasicTable />
        </Box>
      </Grid>
    </Paper>
  );
}
