import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid'; 
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: window.innerHeight - 30
};

export default function EnlargableImage(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const item = props.item;
  return (
    <div>
      <Button onClick={handleOpen}>
        <ImageListItem key={item.desc}>
          <ImageListItemBar title={item.name} />
          <img
            height="auto"
            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.desc}
            loading="lazy"
          />
        </ImageListItem>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
          <Grid container direction="column">
          <Grid container  justifyContent="flex-end">
            {/* <Button onClick={handleClose}>Close</Button> */}
             <IconButton onClick={handleClose} >
            <CloseIcon/>
          </IconButton>
            </Grid>
            <Typography variant="h6" align="center">{item.name}</Typography>
 
            <Button onClick={handleClose}>
              {" "}
              <img
                style={{ maxHeight: window.innerHeight - 50 , maxWidth:  window.innerWidth - 50 }}
                src={`${item.url}`}
                alt={item.desc}
                loading="lazy"
              />
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
