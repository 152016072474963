import * as React from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Box,
  Typography,
  Container,
  Link,
  Collapse,
  IconButton,
} from "@mui/material";
import LinkedIn from "../assets/images/linkedinDark.svg";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Placeholder from "../assets/images/placeholder.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { List, ListItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Edurne from "../assets/images/Bios/Edurne.png";
import Bibiana from "../assets/images/Bios/Bibiana.jpg";
import Sabrina from "../assets/images/Bios/Sabrina.png";
import Grace from "../assets/images/Bios/Grace.jpg";
import Alejandro from "../assets/images/Bios/Alejandro.jpg";

const iconStyle = {
  width: 60,
  height: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
  fill: "#0072ea",
};

const sample = [
  {
    name: "Grace Alvarez",
    email: "grace.alvarez@siemens-energy.com",
    position: (
      <>
        Director de Estrategia, Desarrollo de Negocios y Excelencia para el área
        de Servicios de Generación de Energía e Industrias
      </>
    ),
    image: Grace,
    linkedIn:
      "https://www.linkedin.com/in/e-graciela-grace-alvarez-mba-8149bab",
    bullets: [
      "Director de Estrategia, Desarrollo de Negocios y Excelencia para el área de Servicios de Generación de Energía e Industrias incluyendo Gas & Petróleo para la Región de Latinoamérica. Grace colabora directamente con las áreas de Ventas,  Mercadeo, Operaciones, Finanzas y Digitalización, entre otros, acompañando a los clientes desarrollando una mayor eficiencia en sus procesos, incluyendo optimización en la operación de sus equipos.",
      "20 años en el área de Energía liderando negociaciones internacionales en más de 20 países. Comenzó en la Sucursal de Siemens Energy de Argentina, siguieron 6 años en la casa matriz de Alemania y actualmente lleva más de 12 en Florida, Estados Unidos lo que le han dado una variada y nutrida experiencia en el campo.",
      "Grace es graduada de la Universidad de Buenos Aires, Facultad de Derecho en Argentina con un Master en Negocios de Crummer Business School de la Universidad de Rollins, Florida, Estados Unidos",
      "Habla fluido Español, Alemán e Inglés",
      "Fotografía es uno de sus pasiones así como también varios deportes: senderismo de montaña, ciclismo y natación.",
    ],
  },
  {
    name: "Edurne Turloff",
    email: "edurne.turloff@siemens-energy.com",
    position: (
      <>
        Estrategia y Desarrollo de Negocios <br />
      </>
    ),
    image: Edurne,
    linkedIn: "https://www.linkedin.com/in/edurne-turloff-315a566b",
    bullets: [
      "Especialista financiera en el área de control y reducción de costos, Edurne forma parte del equipo de Business Operations de Gas Services Latinoamérica , con foco en estrategia y desarrollo de negocios.  Su experiencia en planificación, presupuesto, análisis comercial y de negocios, por más de 17 años en Siemens, le han llevado a liderar proyectos internacionales de digitalización (CRM), simplificación y gerencia de cambios organizacionales. Actualmente también coordina el plan de comunicaciones internas de Gas Services Latinoamérica.",
      "Su carrera profesional se inicia en el área de contraloría y finanzas para la industria Petroquímica (PDVSA) en Caracas, Venezuela, donde trabajo por 11 años",
      "Edurne es graduada en Administración de Empresas, mención mercadeo, en la Universidad Católica Andres Bello, Caracas, Venezuela. Y posee maestría en Contaduría Pública de la misma Universidad.",
      "Nacida en Venezuela, reside en la ciudad de Orlando, FL.  Estados Unidos, desde hace 19 años. ",
      "En su tiempo libre disfruta viajar, compartir en familia y con amigos un buen asado, y ver el amanecer en la playa.",
    ],
  },
  {
    name: "Sabrina Majul",
    email: "sabrina.majul@siemens-energy.com",
    position: (
      <>
        Communications Professional para Gas Services Latinoamérica<br />{" "}
      </>
    ),
    image: Sabrina,
    linkedIn: "https://ar.linkedin.com/in/sabrina-majul-15866453",
    bullets: [
      "Nacida en la ciudad de Buenos Aires, vive desde muy corta edad en la ciudad de Rosario, Santa Fe – lugar dónde actualmente reside.",
      "Licenciada en Comercio Internacional y en Comercialización en la Universidad Abierta Interamericana. Especializada en Marketing Digital.",
      "Comenzó hace 3 años trabajando en Siemens como Junior Communications Professional para Servicios de Argentina & Uruguay. A los pocos meses, comenzó su rol como Joint Functions para Argentina & Uruguay dónde ganó experiencia en Calidad, Estrategia, Business Excellence & Business Development y sumó nuevos desafíos a su carrera en Comunicaciones. Actualmente, se desempeña en su rol de Comunicaciones para Gas Services Latinoamérica.",
      "En su tiempo de ocio, disfruta de la lectura de thrillers, fotografía y pasar tiempo con sus amigos.",
    ],
  },
  {
    name: "Bibiana Guerrero",
    email: "bibiana.guerrero-puerto@siemens-energy.com",
    position: (
      <>
        Estrategia y Desarrollo de Negocios 
        <br />{" "}
      </>
    ),
    image: Bibiana,
    linkedIn: "https://co.linkedin.com/in/bibiana-marcela-guerrero-puerto-21277ba4",
    bullets: [
      "Ingeniera industrial con 8 años de experiencia en Siemens. Bibiana ha desarrollado su carrera en diferentes áreas cómo calidad, estrategia, desarrollo de negocio, excelencia de negocio y marketing para las áreas de Servicios de Generación de Energía Central / Distribuida y Transmisión.",
      "Actualmente se desempeña en el área de Servicios para la Generación de Energía Central y Distribuida con enfoque  en Argentina y Chile en labores de estrategia y desarrollo de negocios.  ",
      "Ha desarrollado proyectos regionales en Latinoamérica y así mismo ha tenido experiencias relevantes con países cómo Colombia, Bolivia y Chile tanto internos cómo con clientes que le han permitido ampliar sus conocimiento del mercado energético en la región. ",
      "Actualmente vive en Bogotá, Colombia ",
      "En su tiempo libre le gusta compartir con su familia, ver películas / series y jugar tenis de campo. ",
      
    ],
  },
  {
    name: "Alejandro Neira",
    email: "alejandro.neira@siemens-energy.com",
    position: (
      <>
        Business Excellence and Operational Solutions
        <br />{" "}
      </>
    ),
    image: Alejandro,
    linkedIn: "https://www.linkedin.com/in/alejandro-neira-8215236b",
    bullets: [
      "Nacido en Bogotá, Colombia, actualmente reside en Orlando, Florida.",
      "Ingeniero de Sistemas  con 4 años de experiencia en excelencia de negocio y calidad, con enfoque en soluciones digitales.",
      "Salesforce Champion para la región de Servicios de Generación, Latinoamérica.",
      "Desarrollador de la aplicación IPOS Plus 2022.",
      "En su tiempo libre disfruta viajar, ir a la playa, pasar tiempo con sus sobrinos y cuidar de su perrita de 2 años de raza Weimaraner.",
    ],
  },
  

];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton aria-label="down" size="large" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Section(contacts, title) {
  return (
    <Grid container spacing={4} sx={{ mt: 1, mb: 5 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ ml: { xs: 3, md: 0 } }}>
          {title}
        </Typography>
      </Grid>
      {contacts.map((contact) => (
        <Grid item key={contact.email} xs={12} sm={6} md={4}>
          <SpeakerCard contact={contact} />
        </Grid>
      ))}
    </Grid>
  );
}

function SpeakerCard({ contact }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 345 }}>
      <CardMedia
        component="img"
        height="350"
        image={contact.image}
        alt={`${contact.name}`}
      />
      <CardContent>
        <Typography variant="h5" component={"h2"}>
          {contact.name}
        </Typography>
        <Typography variant="body1" sx={{ minHeight: 100 }}>
          {contact.position}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container direction="row">
              <Link
                  href={`mailto:${contact.email}?subject=Siemens Energy IPOS Plus 2022`}
                >
                  <IconButton aria-label="email" size="large">
                    <EmailIcon fontSize="large" />
                  </IconButton>
              </Link>
              <Box component="a" href={contact.linkedIn} sx={iconStyle}>
                <img src={LinkedIn} alt="LinkedIn" />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent={"flex-end"}>
              <Button
                sx={{
                  "&:disabled": {
                    color: "black",
                  },
                }}
                disabled
              >
                Ver Más
              </Button>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" />
              </ExpandMore>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              {contact.bullets
                ? contact.bullets.map((bullet) => {
                    return <ListItem>{bullet}</ListItem>;
                  })
                : null}
            </List>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default function Organizers() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container sx={{ display: "flex", position: "relative" }} padding={3}>
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item>
          <Box>
            <Typography
              variant="h5"
              sx={{ mb: 4, mt: 7, fontWeight: "bold", color: "#1B1534" }}
            >
              Organizadores
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 4, mt: 3, color: "#1B1534" }}
            >
              Conozca quiénes son los organizadores del evento.
            </Typography>
          </Box>
        </Grid>
        <Box align="center">{Section(sample, "")}</Box>
      </Grid>
    </Container>
  );
}
