import * as React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Typography, Box, Grid, Container, List, ListItem, Link} from "@mui/material";
import SpecialGuestImage from "../assets/images/juanjosearanguren.png";

import LinkedIn from "../assets/images/linkedin.svg"

const iconStyle = {
  width: 60,
  height: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
  fill: "#0072ea",
};

export default function SingleInfo() {
  const [imgSize, setImgSize] = React.useState("300");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (isMobile) {
      setImgSize("300");
    } else if (isDesktop) {
      setImgSize("450");
    } else setImgSize("500");
  }, [isMobile, isDesktop]);

  const item = {
    display: "flex",
    flexDirection: "column",
    px: 5,
  };
  return (
    <>
      <Box
        component="section"
        sx={{
          flexDirection: "column",
          display: "flex",
          overflow: "hidden",
          mb: 5,
        }}
      >
        <Container
          sx={{
            display: "flex",
            position: "relative",
            bgcolor: "#1B1534",
            p: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5} align="center">
              <img width={imgSize} src={SpecialGuestImage} />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: "white" }}
                  >
                    Juan José Aranguren
                  </Typography>
                  <Box component="a" href="https://ca.linkedin.com/company/energy-consilium" target="_new" sx={iconStyle}>
                    <img src={LinkedIn} alt="LinkedIn" /> 
                  </Box>
                  <Typography
                    variant="h7"
                    sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: "white" }}
                  >
                    <Link href="http://www.energyconsilium.com/">Energy Consilium</Link>
                  </Typography>
                  <Typography sx={{ mb: 4, color: "white" }}>
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: 2,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem>
                        Actualmente es director de la consultora ENERGY
                        Consilium y director de la Diplomatura en Gestión de
                        Transiciones Energéticas y la Maestría en Desarrollo
                        Energético Sustentable del Instituto Tecnológico Buenos
                        Aires.
                      </ListItem>
                      <ListItem>
                        Fue ministro de Energía y Minería de Argentina desde
                        Diciembre de 2015 a Junio 2018.
                      </ListItem>
                      <ListItem>
                        Trabajó en Shell durante 37 años, ocupando diversas
                        posiciones en Argentina, Australia y el Reino Unido. En
                        enero de 2003 fue designado vicepresidente de Shell
                        Latinoamérica y presidente de Shell Argentina hasta su
                        retiro en junio 2015. Fue presidente de la Cámara de la
                        Industria del Petróleo de Argentina de 2001 a 2005.
                      </ListItem>
                      <ListItem>
                        En 2008, la Fundación Konex le otorgó el diploma al
                        mérito y el premio Konex de platino como el líder de la
                        industria de la última década. En 2009, fue premiado
                        como el líder empresario del año en el sector industrial
                        por la Asociación de Dirigentes de Empresas. En 2013 fue
                        elegido el CEO del año en una encuesta llevada a cabo
                        por Price Waterhouse Coopers y El Cronista. En 2014
                        también fue elegido como el CEO del año según una
                        encuesta entre sus pares conducida por Ernst & Young.
                      </ListItem>
                      <ListItem>
                      Juanjo es Ingeniero Químico y Licenciado en Ingeniería de Sistemas de la Universidad de Buenos Aires.
                      </ListItem>
                    </List>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
