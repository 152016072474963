import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup
} from "@mui/material";



import Header from "../assets/images/headerImage3.png";
import axios from "axios";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [attendance, setAttendance] = useState("");
  const [dietary, setDietary] = useState("");
  const [accommodations, setAccommodations] = useState("");
  const [sheraton, setSheraton] = useState("true");
  const [hotelName, setHotelName] = useState("Sheraton");
  const [country, setCountry] = useState(""); 

  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    setErrorMsg("The registration period has expired");
    return; 
    if (
      name.length < 1 ||
      email.length < 1 ||
      company.length < 1 ||
      hotelName.length < 1 ||
      country.length < 1
    ) {
      console.log("Invalid Input");
      setErrorMsg("Por favor complete todos lo campos.");
      return;
    }

    axios
      .put("/attendees/update", {
        email : email.toLowerCase(),
        name,
        company,
        hotel: hotelName,
        accommodations, 
        attendance, 
        dietary,
        country
      })
      .then(function (response) {
        if (!response.data.email) {
          setErrorMsg(
            "Por favor utilice el correo electrónico del que recibió la invitación. Si sigue teniendo problemas por favor contactarse con gsv_isv_la_communications.energy@internal.siemens-energy.com"
          );
          return;
        }
        console.log("response in registerUser:", response);
        navigate("/thanks");
      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        console.log(error);
        console.log(`${email}, ${name}, ${company}`);
      });
  };

  return (
    <Box align="center">
      <Grid container xs={12}>
        <img width="100%" height="auto" src={Header} alt={"SEIPOS+ Header"} />
      </Grid>

      <Box component="form" noValidate autoComplete="off" padding={3}>
        <Grid
          container
          direction="column"
          spacing={4}
          xs={12}
          sm={6}
          align={"left"}
          sx={{ minWidth: "100%"}}
        >

          <Grid item>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{}}>Bienvenido a nuestra página de registración</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item xs={12}>
                <Typography>
                  Estimado,<br /><br />
                  Bienvenido a la página de <b>IPOS Plus 2022</b>.  Regístrese completando la siguiente información. Por favor, en caso de tener alguna pregunta, <Link href="mailto:gsv_isv_la_communications.energy@internal.siemens-energy.com?subject=Siemens Energy IPOS+ 2022">contáctenos</Link>.
                  <br/><br/>*Requerido
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item xs={12}>
                <Typography>1. Nombre y apellido*</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="name"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ minWidth: 200 }}>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item>
                <Typography>2. Correo electrónico*</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ minWidth: 200 }}>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item>
                <Typography>3. Empresa*</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="company"
                  label="Company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ minWidth: 200 }}>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item>
                <Typography>4. ¿De qué ciudad y país nos visita?*</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="country"
                  label="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          {/*this needs to implemeented in backend!!*/}          
          <Grid item xs={12} sx={{ minWidth: 200 }}>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item>
                <Typography>5. Hemos diseñado especialmente este evento para 3 días. Si tiene comentarios adicionales sobre su asistencia, díganos aquí.</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="attendance"
                  label="Attendance comments"
                  value={attendance}
                  onChange={(e) => setAttendance(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 4, minWidth: 200 }}>
            <Grid container direction="column" padding="1" align="left">
              <Grid item>
                <Typography>6. ¿En qué hotel se hospedará?*</Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={"Sheraton"}
                    onChange={(e) => {
                      if (e.target.value === "true") {
                        setHotelName("Sheraton");
                        setSheraton("true");
                      } else {
                        setHotelName("");
                        setSheraton("false");
                      }
                    }}
                    value={sheraton}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={"true"}
                      control={
                        <Radio
                          sx={{
                            color: "#1B1534",
                            "&.Mui-checked": {
                              color: "#1B1534",
                            },
                          }}
                        />
                      }
                      label="Sheraton (Preferente)"
                    />
                    <FormControlLabel
                      value={"false"}
                      control={
                        <Radio
                          sx={{
                            color: "#1B1534",
                            "&.Mui-checked": {
                              color: "#1B1534",
                            },
                          }}
                        />
                      }
                      label="Otro"
                    />
                    {sheraton === "true" ? null : (
                      <TextField
                        fullWidth
                        id="Other"
                        label="Other*"
                        value={hotelName}
                        onChange={(e) => setHotelName(e.target.value)}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} sx={{ minWidth: 200 }}>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item>
                <Typography>7. ¿Tiene alguna restricción alimentaria o alergia que debamos tener en cuenta?</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="dietary"
                  label="Dietary Restrictions"
                  value={dietary}
                  onChange={(e) => setDietary(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ minWidth: 200 }}>
            <Grid container align="left" direction="column" padding={1}>
              <Grid item>
                <Typography>8. ¿Requiere asistencia especial de movilidad?</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="accommodations"
                  label="Special Accommodations"
                  value={accommodations}
                  onChange={(e) => setAccommodations(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>



          {errorMsg.length > 0 ? (
            <Grid item>
              <Typography color="red">{errorMsg}</Typography>
            </Grid>
          ) : null}
          <Grid item>
            <Grid container direction="column" align="center">
              <Box color="white">
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ borderRadius: 8, backgroundColor: "#1B1534", mb: 2 }}
                  onClick={handleSubmit}
                >
                  Enviar
                </Button>
              </Box>
              <Link color="#1B1534" href={"/"}>
                ¿Ya está registrado?
              </Link>
            </Grid>
          </Grid>

          <Grid item>
              <Typography variant="h5">Aviso de privacidad de datos</Typography>
          </Grid>

          <Grid item>
              <Typography>
              La protección de sus datos y el reconocimiento de sus derechos con respecto al procesamiento de sus datos personales es importante para Siemens Energy. Como parte de este evento, Siemens Energy puede procesar sus datos personales de acuerdo con el Aviso de privacidad de datos de Siemens Energy, que se puede encontrar <Link href="https://www.siemens-energy.com/mx/es/general/politica-de-privacidad.html">aquí</Link>.
              </Typography>
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
}
