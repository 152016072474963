import {useState, useEffect} from "react";
import { useMemo } from "react";
import { Box, Button } from "@mui/material";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function MapWrap(props) {
  const [width, setWidth] = useState("300");
  const [height, setHeight] = useState("300");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(""); 
  const [link, setLink] = useState(""); 

  const handleClickOpen = (elocation, elink) => {
    setLocation(elocation); 
    setLink(elink)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  function Map() {
    return (
      <Box >
        <GoogleMap
          zoom={10}
          center={{ lat: props.lat, lng: props.lng }}
          mapContainerStyle={{
            width: width,
            height: height,
          }}
        >
          <>
            {props.markers
              ? props.markers.map((marker) => {
                  return (
                    <>
                      <Marker
                        clickable
                        onClick={() => {handleClickOpen(marker.location, marker.link)}}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        label={marker.location}
                      />
                    </>
                  );
                })
              : null}
          </>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{location}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This link will redirect you to google maps.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  window.location.href = link;
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </GoogleMap>
      </Box>
    );
  }

  useEffect(() => {
    if (isMobile) {
      setWidth("70vw");
      setHeight("50vh");
    } else if (isDesktop) {
      setWidth("30vw");
      setHeight("50vh");
    } else {
      setWidth("70vw");
      setHeight("50vh");
    }
  }, [isMobile, isDesktop]);

  const render = (status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Wrapper apiKey={"AIzaSyBmUmVPHqVLZpzbMOKuYhKkIJKGdEy2z8M"} render={render}>
      <Map />
    </Wrapper>
  );
}
